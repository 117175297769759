<template>
  <div id="esquema" class="encuadrat2">
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>
    <div class="container-fluid">
      <!-- First Row: Genconsur component -->
      <div class="row">
        <div class="col-12">
          <div class="Genconsur-component_consum">
            <Genconsur_component_consum :solarPower="pGenerada" :consumptionPower="pConsumida" />
          </div>
        </div>
      </div>

      <!-- Second Row: DadesMeteo, dropdown button, and refresh button -->
      <div class="row align-items-center">
        <div class="col">
          <div class="dades-meteo">
            <DadesMeteo v-if="flag" v-bind:data_lectura="dataLectura" :idInstalacio="idInstalacio" v-bind:hora_lectura="horaLectura" :key="reload"/>
          </div>
        </div>
        <div class="col-auto">
          <b-button variant="none" id="popover-target-1" class="p-0">
            <img src="../assets/dropdown.png" class="icon-img">
          </b-button>
          <b-popover target="popover-target-1" triggers="hover" placement="right">
            <template #title>Generadors Associats</template>
            <table class="consums-generadors">
              <tr>
                <th>Generador</th>
                <th></th>
                <th></th>
                <th></th>
                <th>Coeficient (%)</th>
              </tr>
              <tr v-for="value in generadorsApi" :key="value.id">
                <th>
                  <a :href="'/vistaGenerador/' + value.id" target="_blank">
                    {{value.nom}}
                  </a>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  <a>{{(value.beta * 100).toFixed(2)}}</a>
                </th>
              </tr>
            </table>
          </b-popover>
        </div>
        <div class="col-auto">
          <div class="refresh-container d-flex justify-content-center align-items-center">
            <b-button type="button" variant="success" @click="esquemaAxios" v-show="!carregant" class="refresh-button">
              Refrescar
            </b-button>
            <div class="spinner-border text-success ml-2" role="status" v-show="carregant"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import DadesMeteo from './DadesMeteo.vue';
import Genconsur_component_consum from './genconsur_component_consum.vue';

export default {
  props: {
    idInstalacio: {},
    consum: {},
    generador: {},
    idComunitat: {},
    asistema: {},
  },
  components: {
    Genconsur_component_consum,
    DadesMeteo,
  },
  data() {
    return {
      codiInstalacio: this.idInstalacio,
      data: new Date(),
      disponible: true,
      esquemaApi: "",
      pGenerada: 0,
      pConsumida: 0,
      pConXar: 0,
      pEntXar: 0,
      SOC: 0,
      pBateria: 0,
      pInversor: 0,
      carregant: false,
      placa_inversor: true,
      inversor_bateria: false,
      bateria_inversor: true,
      inversor_casa: true,
      casa_xarxa: false,
      xarxa_casa: true,
      reload: "0",
      flag: true,
      ids: "",
      value: "",
      generadorsApi: "",
      codiCE: this.idComunitat,
      dataLectura: "",
      horaLectura: "",
      unitat: "W"
    };
  },
  mounted() {
    this.esquemaAxios();
  },
  methods: {
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    async esquemaAxios() {
      this.data = new Date();
      const year = this.data.getFullYear();
      const month = this.data.getMonth() + 1;
      const day = this.data.getDate();
      const hour = this.data.getHours();
      const minutes = this.data.getMinutes();

      console.log(this.codiInstalacio);
      console.log("SISTEMA_CONSUM", this.asistema);
      if (this.asistema === 3 || this.asistema === 4) {
        this.unitat = "5W";
      } else if (this.asistema === 5) {
        this.unitat = "kW";
      }
      else if (this.asistema === 7) {
        this.unitat = "kW";
      } else if (this.asistema === 9) {
        this.unitat = "kW";
      }
      else if (this.asistema === 10) {
        this.unitat = "kW";
      }
      this.carregant = true;

      await axios.get(this.$domini + `esquemaConsum?Instalacio=${this.codiInstalacio}&codiCE=${String(this.codiCE)}&any=${year}&mes=${String(month).padStart(2, '0')}&dia=${String(day).padStart(2, '0')}&hora=${String(hour).padStart(2, '0')}&minut=${String(minutes).padStart(2, '0')}`, { withCredentials: true, timeout: 2000000 })
        .then((response) => {
          this.esquemaApi = response.data;
        })
        .catch((err) => {
          this.carregant = false;
          this.$refs['my-modal'].show();
          console.error(err);
          this.disponible = false;
        });

      // Classificació de les informacions rebudes
      this.pGenerada = this.esquemaApi.pGenerada;
      this.pConsumida = this.esquemaApi.pConsumida;
      this.pEntXar = this.esquemaApi.pEntXar;
      this.pConXar = this.esquemaApi.pConXar;
      this.pBateria = this.esquemaApi.pBateria;
      this.SOC = this.esquemaApi.SOC;
      this.pInversor = this.esquemaApi.pInversor;
      this.placa_inversor = this.esquemaApi.placa_inversor;
      this.inversor_bateria = this.esquemaApi.inversor_bateria;
      this.inversor_casa = this.esquemaApi.inversor_casa;
      this.bateria_inversor = this.esquemaApi.bateria_inversor;
      this.casa_xarxa = this.esquemaApi.casa_xarxa;
      this.xarxa_casa = this.esquemaApi.xarxa_casa;
      this.dataLectura = `${String(day).padStart(2, '0')}${String(month).padStart(2, '0')}${year}`;
      this.horaLectura = `${String(hour).padStart(2, '0')}${String(minutes).padStart(2, '0')}`;
      this.carregant = false;
      this.flag = true;
      this.reload++;
      console.log("comunitatesq_cons", this.codiCE);
      console.log("comunitatesq_codiInstalacio", this.codiInstalacio);
      // Obtenim llista de generadors
      await axios.get(this.$domini + `generadorsConsum?Consum=${this.idInstalacio}&codiCE=${String(this.codiCE)}`, { withCredentials: true, timeout: 2000000 })
        .then((response) => {
          this.generadorsApi = response.data;
          console.log("Generadors",this.generadorsApi);

        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.b-popover.popover {
  text-align: center;
}
.Genconsur-component_consum {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dades-meteo {
  
  justify-content: center;
  align-items: center;
}

.refresh-container {

  display: flex;
  justify-content: center;
  align-items: center;
}

.refresh-button {
  width: 100px; /* Adjust the width as necessary */
  margin-right: 0px; /* Space between button and spinner */
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 75px;
}

.b-popover.popover {
  font-size: 13px;
}
@media (max-width: 575.98px) {
  .b-popover.popover {
    font-size: 9px;
  }
}

@media (min-width: 576px) and (max-width: 789.98px) {
  .b-popover.popover {
    font-size: 10px;
  }
}

@media (min-width: 790px) and (max-width: 995.98px) {
  .b-popover.popover {
    font-size: 11px;
  }
}

@media (min-width: 996px) and (max-width: 1199.98px) {
  .b-popover.popover {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .b-popover.popover {
    font-size: 13px;
  }
}

.consums-generadors {
  font-size: 10px;
}

@media (max-width: 575.98px) {
  .consums-generadors {
    font-size: 8px;
  }
}

@media (min-width: 576px) and (max-width: 789.98px) {
  .consums-generadors {
    font-size: 9px;
  }
}

@media (min-width: 790px) and (max-width: 995.98px) {
  .consums-generadors {
    font-size: 10px;
  }
}

@media (min-width: 996px) and (max-width: 1199.98px) {
  .consums-generadors {
    font-size: 11px;
  }
}

@media (min-width: 1200px) {
  .consums-generadors {
    font-size: 12px;
  }
}

.icon-img {
  width: 50px;
  height: 50px;
  margin-right: 0px;
}

.refresh-container {
  margin: auto;
}


</style>

<template>
  <div id="esquema" class="encuadrat2">
    <!-- Modal -->
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <b-container id="taulaBarLine7" fluid>
      <div id="ElemSuperior">
        <b-row>
          <b-col class="d-flex jusitify-items-center align-items-center">
            <b-button variant="link" @click="decrementDate">
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
            <datepicker id="datepickerD" input-class="triadates" :placeholder="dateString" v-model="model.date" @input="formatPicker" :language="ca" :format="DatePickerFormat"></datepicker>
            <b-button variant="link" @click="incrementDate">
              <b-icon icon="chevron-right"></b-icon>
            </b-button> 
          </b-col>
          <b-col id="but">
            <button type="button" class="btn-success3" @click="functionAnual()" :class="{ 'active-button': tipus === 1 }">Anual</button>
          </b-col>
          <b-col id="buta">
            <button type="button" class="btn-success3" @click="functionMensual()" :class="{ 'active-button': tipus === 2 }">Mensual</button>
          </b-col>
          <b-col id="butb">
            <button type="button" class="btn-success3" @click="functionDiaria()" :class="{ 'active-button': tipus === 3 }">Diària</button>
          </b-col>
        </b-row>
        <div v-if="!chartData"><p class="custom-text-style">{{ text }}</p></div>
      </div>
     
      <b-row>
        <table id="caracteristiques">
          <tr>
            <td><b>E. Cons.</b></td>
            <td><b>E. Ent.</b></td>
            <td><b>E. Auto.</b></td>
          </tr>
          <tr>
            <td>{{ this.formatUnit(this.sumConsumida) }}</td>
            <td>{{ this.formatUnit(this.sumEntregada) }}</td>
            <td>{{ this.formatUnit(this.sumAutoconsum) }}</td>
          </tr>
        </table>
      </b-row>
      <b-row>
        <div id="graficalineas2" class="chart-container">
          <div class="chart-wrapper">
            <ChartStackedBar :chart-data="chartData" />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import datepicker from 'vuejs-datepicker';
import ChartStackedBar from "./ChartStackedBar.js";
import { ca } from 'vuejs-datepicker/dist/locale';

export default {
  components: {
    datepicker,
    ChartStackedBar,
  },
  props: {
    idInstalacio: {},
    idComunitat: {},
    asistema: {}
  },
  data() {
    return {
      ca: ca,
      codiCE: this.idComunitat,
      carregant: 0,
      chartData: null,
      rgbastring: '',
      eConsTotal: 0,
      unitat: '',
      formattedDate: '',
      formattedDay: '',
      formattedMonth: '',
      formattedYear: '',
      formattedHour: '',
      infoapi: null,
      infoapi1: null,
      infoapi2: null,
      eConsumida: [],
      ListaValores: [],
      amagaDiaria: false,
      ListaHora: [],
      tipus: 1, // Predefinit que la grafica sigui diaria
      model: {
        date: new Date()
      },
      date: {
        from: null,
        to: null,
        month: null,
        year: null,
      },
      dateString: "",
      DatePickerFormat: "dd/MM/yyyy",
      // Afegides per sumar vectors
      sumEntregada: 0,
      sumAutoconsum: 0,
      sumConsumida: 0,
    };
  },

  mounted() {
    this.iniciaAxios();
  },

  methods: {
    showDate(date) {
      this.date = date;
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    async iniciaAxios() {
      var d = new Date();
      this.formattedDate = d.getUTCDate() + "/" + (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
      this.formattedDay = d.getUTCDate();
      this.formattedMonth = d.getUTCMonth() + 1;
      this.formattedYear = d.getUTCFullYear();
      console.log("ANY_ECONS", this.formattedYear);
      //this.formattedYear = 2023;
      this.dateString = this.formattedDay.toString().padStart(2, '0') + "/" + this.formattedMonth.toString().padStart(2, '0') + "/" + this.formattedYear.toString();
      this.tipus = 1;
      if (this.asistema === 3 || this.asistema === 4) {
        this.unitat = "Wh";
      } else if (this.asistema === 5) {
        this.unitat = "kWh";
      }
     else if (this.asistema === 7) {
        this.unitat = "kWh";
      }
      else if (this.asistema === 9) {
        this.unitat = "kWh";
      }
      else if (this.asistema === 10) {
        this.unitat = "kWh";
      }
      await this.PonerAxios();
    }, decrementDate() {

      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() - 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },
    incrementDate() {
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() + 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },

    async formatPicker() {
      var d = new Date(this.model.date);
      this.formattedDate = d.getUTCDate() + "/" + (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
      this.formattedDay = d.getUTCDate();
      this.formattedMonth = d.getUTCMonth() + 1;
      this.formattedYear = d.getUTCFullYear();
      console.log("ANY_ECONS2", this.formattedYear);
      //this.formattedYear = 2023;
      await this.PonerAxios();
    },

    async functionAnual() {
      this.tipus = 1;
      await this.PonerAxios();
    },

    async functionMensual() {
      this.tipus = 2;
      await this.PonerAxios();
    },

    async functionDiaria() {
      this.tipus = 3;
      await this.PonerAxios();
    },

    async PonerAxios() {
      this.ListaValores = [];
      this.ListaHora = [];
      this.eConsumida = [];
      this.infoapi = null;
      this.infoapi2 = null;
      this.infoapi1 = null;
      this.text = null;
      console.log(this.tipus);
      await this.BuscarAxios();

      // Calcular les sumes després de BuscarAxios
      this.sumEntregada = this.sumarVectors(this.infoapi.Surplus);
      this.sumAutoconsum = this.sumarVectors(this.infoapi.Autoconsum);
      this.sumConsumida = this.sumarVectors(this.infoapi2.eConsumida);
    },

    async BuscarAxios() {
      this.carregant = 1;
      console.log("COMUNITAT: ", this.idComunitat);
      console.log("Instalacio: ", this.idInstalacio);
      try {
        const response1 = await axios.get(`${this.$domini}energiaCE3?idInstalacio=${this.idInstalacio}&tipus=${this.tipus}&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true }, { timeout: 2000000 });
        this.infoapi = response1.data;
        console.log("infoapi_energiaCE3", this.infoapi);
        console.log("infoapi_energiaCE3", this.infoapi.Surplus);
        console.log("infoapi_energiaCE3", this.infoapi.Autoconsum);

        const response3 = await axios.get(`${this.$domini}energiaConsumicio?idInstalacio=${this.idInstalacio}&tipus=${this.tipus}&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true }, { timeout: 2000000 });
        this.infoapi2 = response3.data;
        this.eConsTotal = this.infoapi2.eConsTotal;
        console.log("consINFOapi: ", this.infoapi2);
        console.log("consINFOapi2: ", this.infoapi2.eConsumida);

      } catch (err) {
        this.handleError(err);
      }

      this.carregant = 0;
      await this.PonerGrafica();
    },

    PonerGrafica() {
      const Surplus = this.infoapi.Surplus;
      const Autoconsum = this.infoapi.Autoconsum;

      this.chartData = {
        labels: this.infoapi.temps,
        datasets: [],
      };

      this.chartData.datasets.push({
        backgroundColor: `rgba(255, ${170 - 30}, ${80 - 40}, 1)`,
        borderColor: "#0c4105c7",
        borderWidth: 0.5,
        label: "Consum (" + this.unitat + ")",
        data: this.infoapi2.eConsumida,
        stack: 'Stack 0',
      });
      //egen-egenxar
      this.chartData.datasets.push({
        backgroundColor: "#0c4105c7",
        borderColor: `rgba(255, ${170 - 30}, ${80 - 40}, 1)`,
        borderWidth: 0.5,
        label: "Autoconsum (" + this.unitat + ")",
        data: Autoconsum,
        stack: 'Stack 1',
      });

      this.chartData.datasets.push({
        backgroundColor: `rgba(54, 162, 235, 0.5)`,
        borderColor: `rgba(54, 162, 235, 1)`,
        borderWidth: 0.5,
        label: "Excedents (" + this.unitat + ")",
        data: Surplus,
        stack: 'Stack 1',
      });
    },

    sumarVectors(vector) {
      return vector.reduce((acc, val) => acc + val, 0);
    },
    formatUnit(value) {
        if (value >= 1000) {
            return (value / 1000).toFixed(2) + " MWh";
        } else if (value < 1) {
            return (value * 1000).toFixed(2) + " Wh";
        } else {
            return (value / 1).toFixed(2) + " KWh";
        }
    },
    handleError(err) {
      console.error("Error:", err);
    },
  },
};
</script>

<style scoped>
#but button {
  margin: 5px;
}
#buta button {
  margin: 5px;
}
#butb button {
  margin: 5px;
}

.active-button {
  background-color: #B6FF9F;
  border-color: #B6FF9F;
  color:black;
}

.custom-text-style {
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.triadates {
  width: 100px;
}
.chart-container {
  position: relative;
  margin: auto;
 
}
.chart-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

</style>

<template>
  <b-container id="meteo" class="mateo bordered-shadow">
    <b-row>
      <b-col class="d-flex align-items-center justify-content-center">
        <img id="termometre" src="../assets/termometre.png">
        &nbsp;
        <span>{{ temperatura }}ºC</span>
      </b-col> <b-col class="d-flex align-items-center justify-content-center">
        <img v-if="figura === 'sol'" id="nuvol" src="../assets/sol1.png">
        <img v-else-if="figura === 'nuvol'" id="nuvol" src="../assets/nuvol2.png">
        <img v-else-if="figura === 'totnuvols'" id="nuvol" src="../assets/totnuvols2.png">
        &nbsp;
        <span>{{ nuvols }}%</span>
      </b-col>
    </b-row>
 
  
  </b-container>
</template>
<script>
import axios from 'axios';
export default {
  props: {
    data_lectura: String,
    hora_lectura: String,
    idInstalacio: String,
  },
  data() {
    return {
      APIkey: process.env.VUE_APP_API_KEY, // Use the env variable
      dadesApi: "",
      temperatura: "...",
      nuvols: "...",
      figura: "sol",
      adrecaApi: "",
      coordenadesApi: "",
    };
  },
  mounted() {
    this.TempsAxios();
  },
  watch: {
    nuvols() {
      this.updateNuvolImage();
    }
  },
  methods: {
    async TempsAxios() {
      try {
        const adrecaResponse = await axios.get(`${this.$domini}trobaAdreca?Instalacio=${this.idInstalacio}`, { withCredentials: true, timeout: 20000 });
        this.adrecaApi = adrecaResponse.data;

        const coordenadesResponse = await axios.get(`https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?address=${this.adrecaApi.adreca}&maxLocations=1&f=json&token=${process.env.VUE_APP_ARCGIS_TOKEN}`, { timeout: 20000 });
        this.coordenadesApi = coordenadesResponse.data;

        this.coordenades = this.coordenadesApi.candidates[0].location;

        const meteoResponse = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${this.coordenades.y}&lon=${this.coordenades.x}&appid=${this.APIkey}`, { timeout: 20000 });
        this.dadesApi = meteoResponse.data;

        this.temperatura = Math.round(this.dadesApi.main.temp - 273.15); // Convert from Kelvin to Celsius
        this.nuvols = Math.round(this.dadesApi.clouds.all); // Cloud percentage
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    updateNuvolImage() {
      if (this.nuvols >= 0 && this.nuvols <= 25) {
        this.figura = "sol";
      } else if (this.nuvols > 25 && this.nuvols <= 75) {
        this.figura = "nuvol";
      } else if (this.nuvols > 75 && this.nuvols <= 100) {
        this.figura = "totnuvols";
      }
    },
  },
}
</script>

<style>
.mateo {
  text-align: left;
  font-size: 12px;
}



#nuvol {

width: 30px;

height: 30px;

}

#termometre {

width: 30px;
height: 30px;


}
@media (max-width: 575.98px) {
  .mateo {
  font-size: 9px;
  text-align:left;
  }
}

@media (min-width: 576px) and (max-width: 789.98px) {
  .mateo {
    font-size: 10px;
    text-align:left;
  }
}

/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 790px) and (max-width: 995.98px) {
  .mateo {
    font-size: 11px;
    text-align:left;
  }

}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 996px) and (max-width: 1199.98px) {
  .mateo {
  font-size: 13px;
  text-align:left;
}

}

/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
  .mateo {
    font-size: 15px;
    text-align:left;
  }
}
 
</style>
<template>
  <div id="comunitats">
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <b-modal ref="modal-inautoritzat" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No tens permís per a accedir a aquesta vista</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="amagaInautoritzat">Tanca</b-button>
    </b-modal>

    <br><br><br>
    <div class="carregar">
    <div class="spinner-border text-success" role="status" v-show="carregant"  style="width: 1.0rem; height: 1.0rem; border-width: 0.2em;"></div>
    <p v-show="carregant">Carregant dades...</p></div><br>
  <b-container id="comunitats-layout">

    

    <b-row class="log">
      <h2><img src="../assets/plaquesSolars.png" width="50vw"><b class="TitleFormulari"> COMUNITAT</b> </h2>
    </b-row>
    <br>
    <b-row>
     
      <b-col>
        <div class="mapa">
        <MapaComunitats :detalls="DadesInst" :ampla="mapaStyle" v-if="carregaMapa"/></div>

      </b-col>
     
      <b-col>
       
        <b-row>
          <b-col>
<select v-model="value" @change="seleccionartipus(value)" class="btn-success5" style=" padding:8px; width: 100%;">
  <option v-for="(any, index) in tipus_type" :key="index" :value="any" st  style="font-weight: bold;color: rgb(66, 65, 71);;">{{ any }}</option>
</select>
</b-col>
                <b-col>
                  <select v-model="selectedAny" @change="seleccionarAny(selectedAny)" class="btn-success5" style=" padding:8px; width: 100%;">
                    <option v-for="(any, index) in anysDisponibles" :key="index" :value="any" st  style="font-weight: bold;color: rgb(66, 65, 71);;">{{ any }}</option>
                  </select>
                </b-col>   
                <b-col v-if="this.buttonshow">
                  <select v-model="selectedNumero" @change="seleccionarMes(selectedNumero)" class="btn-success5" style="padding: 8px; width: 100%; ">
                  
                    <option v-for="numero in 12" :key="numero" :value="numero" style="font-weight: bold; color:rgb(66, 65, 71);">
                      {{ numero }}
                    </option>
                  </select>
                </b-col>  
              
           
         
          </b-row>
          <br>
        <b-container  id="produccions">
        <b-row>
          <b-col>
            <b-row>
              <h4>Autosuficiència</h4>  
              <br>
            </b-row>
            <b-row>
              <div class="bunyol">
            <QAutarquicaBunyol :chartDataAutarquica="dataAutarquica"/> 
            </div>
            
            </b-row>
          </b-col>
          
          <b-col>
            <b-row>
              <h4>Autoconsum</h4>
              <br>
            </b-row>
            <b-row>
              <div class="bunyol">
            <QAutoconsumBunyol :chartDataAutoconsum="dataAutoconsum" /><br>
            </div>
            </b-row>
          </b-col>
          
        </b-row>
       
        <b-row>
          <b-col>
            <br>
            <h4 class="title">Cobertura Solar</h4>
          </b-col>
          <b-col>  
            <div class="valores2">{{CSOLAR}} <span class="unitats2">%</span></div>
          </b-col>
        </b-row>
      </b-container>
        
      </b-col>
      
    </b-row>
    
   
  
    <b-row>
      <b-col rowspan="2">
      <div class="bunyol2">
        <ResumComunitats :codiCE="codiCE" :model="model" />
      </div>
      </b-col>
    </b-row>

  <b-row> 
    <b-col colspan="3">
        <b-container id="taula-comunitats">
        <b-row>
       
          <b-col colspan="7" class="NomApartat"><h3>GENERACIONS</h3></b-col>
          
          <b-col v-if="mostraGeneracions"><button type="button"  class="btn btn-secondary" @click="mostraGeneracions=false"><img id="fletxaAlta" src="../assets/fletxaAlta.png"/></button></b-col>
          <b-col v-if="!mostraGeneracions"><button type="button" class="btn btn-secondary" @click="mostraGeneracions=true"><img id="fletxaBaixa" src="../assets/fletxaBaixa.png"/></button></b-col>
        </b-row>
        <table class="common-table">
        <tr v-if="mostraGeneracions"><!--Definició dels apartats de la taula-->
                    <th class="NomApartat"><h5>Identificador</h5></th>
                    <th class="NomApartat"><h5>Adreça</h5></th>
                    <th class="NomApartat"><h5>Sistema</h5></th>
                    <th class="NomApartat"><h5>Potència</h5></th>
                    <th class="NomApartat"><h5>Capacitat</h5></th>
                    <th class="NomApartat"><h5>Estat</h5></th>
                    <th class="NomApartat"><h5>Alarmes</h5></th>
                    <th class="NomApartat"><h5></h5></th>
        </tr>

        <tbody v-if="mostraGeneracions"><!--Display de els elements de la taula, on s'omple automaticamente depenent del numero d'instalacions-->
                <tr v-for="(Detalls,index) in DadesInst.filter(Detalls => (Detalls.tipus=='1'))"  :key="index">
                    <th class="InfoTaula" bgcolor="white" style="color:black;">
                    <a :href="'/vistaGenerador/'+Detalls.id " v-if="Detalls.tipus=='1'"><p> {{Detalls.nom}}<!--{{Detalls.nom}}--></p></a>
                    </th>  
                
                    <th class="InfoTaula" bgcolor="white" style="color:black;"><p>{{Detalls.adreca}}</p></th>
                   
                    <th class="InfoTaula" bgcolor="white" style="color:black;">
                        <p v-if="Detalls.sistema==1">Enphase</p>
                        <p v-if="Detalls.sistema==2">Huawei</p>
                        <p v-if="Detalls.sistema==3">Solax</p>
                        <p v-if="Detalls.sistema==4">Datadis</p>
                        <p v-if="Detalls.sistema==5">Wattabit</p>
                        <p v-if="Detalls.sistema==6">X</p>
                        <p v-if="Detalls.sistema==7">SunGrow</p>
                        <p v-if="Detalls.sistema==9">Demo</p>
                        <p v-if="Detalls.sistema==10">GICCE</p>
                      
                      </th>  
                    <th class="InfoTaula" bgcolor="white" style="color:black;"><p>{{Detalls.potencia}}</p></th>
                    <th class="InfoTaula" bgcolor="white" style="color:black;"><p>{{Detalls.capacitat}}</p></th>
                    <th class="InfoTaula" bgcolor="white" style="color:black;">
                        <p v-if="Detalls.estat==1">Normal <img src="../images/Green.png" width="20" height="20"></p>
                        <p v-else-if="Detalls.estat==2">Comprova Alarmes <img src="../images/Yellow.png" width="20" height="20"></p>
                        <p v-else-if="Detalls.estat==3">Crítica <img src="../images/Red.png" width="20" height="20"></p>
                        <p v-else-if="Detalls.estat==4">Desconnectada <img src="../images/offline.png" width="20" height="20"></p>
                        <p v-else>{{Detalls.text_estat}} 
                          <img src="../images/Green.png" width="20" height="20" v-if="Detalls.estat==('normal' || 'monitored' | 'on_grid' || 'off_grid')">
                          <img src="../images/Yellow.png" width="20" height="20" v-if="Detalls.estat==('comm' || 'micro' || 'meter' || 'meter-issue' || 'battery' || 'encharge' || 'storage_idle' || 'enpower' || 'warning' || 'nsr' || 'data_upload')">
                          <img src="../images/Red.png" width="20" height="20" v-if="Detalls.estat==('deactivated' || 'power' || 'encharge_issue' || 'enpower_issue' || 'retired' || 'diabled' || 'unused' || 'error' || 'any')">
                          <img src="../images/offline.png" width="20" height="20" v-if="Detalls.estat==('not_monitored' || 'muted' || 'no_data' || 'unknown' || 'grid_unknown')">
                        </p>
                      </th>                      
                        <th class="InfoTaula" bgcolor="white" style="color:black;"><Alarmes :Pendents="Detalls.critiques"/></th>
                    <th class="InfoTaula" bgcolor="white" style="color:black;"><div class="spinner-border text-success" role="status" v-show="carregant"></div><button type="button" class="btn btn-secondary" v-show="!carregant" @click="ComunitatsAxios()">Actualitzar</button></th>
                
                    </tr>
            </tbody>
          </table>
    
          </b-container>
  </b-col>
  </b-row>
  <b-row>
    <b-col colspan="3">
    <b-container id="taula-comunitats">
        <b-row>
          <b-col colspan="7" class="NomApartat"><h3>CONSUMS</h3></b-col>
          
          <b-col v-if="mostraConsums"><button type="button"  class="btn btn-secondary" @click="mostraConsums=false"><img id="fletxaAlta" src="../assets/fletxaAlta.png"/></button></b-col>
          <b-col v-if="!mostraConsums"><button type="button" class="btn btn-secondary" @click="mostraConsums=true"><img id="fletxaBaixa" src="../assets/fletxaBaixa.png"/></button></b-col>
        </b-row>
        <table class="common-table">
        <tr v-if="mostraConsums"><!--Definició dels apartats de la taula-->
                    <th class="NomApartat"><h5>Identificador<br>consum</h5></th>
                    
                    <th class="NomApartat"><h5>Adreça</h5></th>
                    <th class="NomApartat"><h5>Sistema</h5></th>
                    <th class="NomApartat"><h5>Potència <br> contractada</h5></th>
                    <th class="NomApartat"><h5>Comercialitzadora</h5></th>
                  
                    <th class="NomApartat"><h5>Estat</h5></th>
                    <th class="NomApartat"><h5></h5></th>
        </tr>

        <tbody v-if="mostraConsums"><!--Display de els elements de la taula, on s'omple automaticamente depenent del numero d'instalacions-->
                <tr v-for="(Detalls,index) in DadesInst.filter(Detalls => (Detalls.tipus=='2'))"  :key="index"  >
                  <th class="InfoTaula" bgcolor="white" style="color:black;">
                      <a :href="'/vistaConsum/'+Detalls.id" v-if="Detalls.tipus=='2'">
                          <p>{{ Detalls.nom }}</p>
                          
                      </a>
                  </th>
                    <th class="InfoTaula" bgcolor="white" style="color:black;"><p>{{Detalls.adreca}}</p></th>
                    <th class="InfoTaula" bgcolor="white" style="color:black;">
                        <p v-if="Detalls.sistema==1">Enphase</p>
                        <p v-if="Detalls.sistema==2">Huawei</p>
                        <p v-if="Detalls.sistema==3">Solax</p>
                        <p v-if="Detalls.sistema==4">Datadis</p>
                        <p v-if="Detalls.sistema==5">Wattabit</p>
                        <p v-if="Detalls.sistema==6">X</p>
                        <p v-if="Detalls.sistema==7">SunGrow</p>
                        <p v-if="Detalls.sistema==9">Demo</p>
                        <p v-if="Detalls.sistema==10">GICCE</p>
                      
                      
                      </th>   
                    <th class="InfoTaula" bgcolor="white" style="color:black;"><p>{{Detalls.potencia}}</p></th>
                    <th class="InfoTaula" bgcolor="white" style="color:black;"><p>{{Detalls.nomComercialitzadora}}</p></th>
                    
                    <th class="InfoTaula" bgcolor="white" style="color:black;">
                        <p v-if="Detalls.estat==1">Normal <img src="../images/Green.png" width="20" height="20"></p>
                        <p v-else-if="Detalls.estat==2">Comprova Alarmes <img src="../images/Yellow.png" width="20" height="20"></p>
                        <p v-else-if="Detalls.estat==3">Crítica <img src="../images/Red.png" width="20" height="20"></p>
                        <p v-else-if="Detalls.estat==4">Desconnectada <img src="../images/offline.png" width="20" height="20"></p>
                        <p v-else>{{Detalls.text_estat}} 
                          <img src="../images/Green.png" width="20" height="20" v-if="Detalls.estat==('normal' || 'monitored' | 'on_grid' || 'off_grid')">
                          <img src="../images/Yellow.png" width="20" height="20" v-if="Detalls.estat==('comm' || 'micro' || 'meter' || 'meter-issue' || 'battery' || 'encharge' || 'storage_idle' || 'enpower' || 'warning' || 'nsr' || 'data_upload')">
                          <img src="../images/Red.png" width="20" height="20" v-if="Detalls.estat==('deactivated' || 'power' || 'encharge_issue' || 'enpower_issue' || 'retired' || 'diabled' || 'unused' || 'error' || 'any')">
                          <img src="../images/offline.png" width="20" height="20" v-if="Detalls.estat==('not_monitored' || 'muted' || 'no_data' || 'unknown' || 'grid_unknown')">
                        </p>
                      </th>       
                    <th
                     class="InfoTaula" bgcolor="white" style="color:black;"><div class="spinner-border text-success" role="status" v-show="carregant"></div><button type="button" class="btn btn-secondary" v-show="!carregant" @click="ComunitatsAxios()">Actualitzar</button></th>
                    </tr>
            </tbody>
            </table>
          </b-container>
        </b-col>
  </b-row>

</b-container>
  </div>
</template>

<script>

import { ca } from 'vuejs-datepicker/dist/locale'; 
import axios from 'axios';
import Alarmes from '../components/Alarmes.vue';
import MapaComunitats from '../components/MapaComunitats.vue';
import ResumComunitats from '../components/ResumComunitats.vue';
import QAutarquicaBunyol from '../components/QAutarquicaBunyol.vue';
import QAutoconsumBunyol from '../components/QAutoconsumBunyol.vue';

export default {
  components: {
    Alarmes,
    MapaComunitats,
    ResumComunitats,
    QAutarquicaBunyol,
    QAutoconsumBunyol,

  },

  data() {
    return {
      tipus_type:['Anual','Mensual'],
      tipusR:"1",
      daysList: [],
      Instalacio: "",
      DetallsAPI: "",
      DadesInst: [],
      filter: [],
      estatInst: 0,
      codiCE: "",
      carregant: false,
      mostraGeneracions: false,
      mostraConsums: false,
      carregaMapa: false,
      dataApi: "",
      CSOLAR: "",
      percAutoconsum: "",
      QuotaAutarquica: "",
      dataAutoconsum: "",
      dataAutarquica: "",
      respostaApi: "",
      dadesCarregades: false, 
      windowWidth: window.innerWidth,
      ca: ca,
      anysDisponibles: [], 
      unitat:'',
      formattedDate: '',
      formattedDay: '',
      formattedMonth: '',
      formattedYear: '',
      formattedHour: '',
      infoapi: '',
      model: {
        date: new Date()
      },
      date: {
        dateString: '',
        day: null,
        month: null,
        year: null,
      },
   
      chartData: '',
      text:""
    };
  },
  computed: {
    mapaStyle() {
      if (this.windowWidth < 750) {
        return 'height: 40vh; width: 85vw;';
      } else {
        return 'height: 40vh; width: 45vw;';
      }
    }
  },
  mounted() {
    
    this.codiCE = this.$route.params.id;


    this.iniciaAxios();
    this.ComunitatsAxios();
    this.calcularAnys();
    window.addEventListener('resize', this.updateWindowWidth);
    this.calcularD();
  }, 
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  
  methods: {
    seleccionartipus(value){
      
      if (value===this.tipus_type[0])
        {
          this.tipusR="1";
          this.buttonshow=false;
          console.log("debugbutton,",this.buttonshow)
        }
      if (value===this.tipus_type[1])
      {
        this.tipusR="2";
        this.buttonshow=true;
        console.log("debugbutton,",this.buttonshow)
      }

      this.updateDaysList();
      this.updateModelDate();
    

    },
    calcularD(){
      
      const currentDate = new Date();
      this.value= this.tipus_type[0];
      this.selectedAny = currentDate.getFullYear();
      this.selectedNumero = currentDate.getMonth() + 1; // Months are zero-based in JavaScript
      this.selecteddia = currentDate.getDate();
      this.diaSeleccionat = currentDate.getDate();
      this.mesSeleccionat = currentDate.getMonth() + 1; 
      this.anySeleccionat = currentDate.getFullYear();
      this.seleccionarMes(this.mesSeleccionat);
    },
    
    seleccionarAny(any) {
    this.anySeleccionat = any;
    console.log("Any seleccionat", any);
    
    this.updateDaysList();
    this.updateModelDate();
  },
  seleccionarMes(mes) {
    this.mesSeleccionat = mes;
    console.log("Mes seleccionat", mes);
    this.updateDaysList();
    this.updateModelDate();
  },
  seleccionarDia(dia) {
    this.diaSeleccionat = dia;
    console.log("Dia seleccionat", dia);
    this.updateModelDate();
  },
  updateModelDate() {
    const newDate = new Date(this.anySeleccionat, this.mesSeleccionat - 1, this.diaSeleccionat);
    this.model.date = newDate;
    this.formatPicker();
  },
  updateDaysList() {
    // Get the number of days in the selected month and year
    const currentDate = new Date();
    this.diaSeleccionat = currentDate.getDate();
  },
    
    async calcularAnys() {
    

    try {
      const response = await axios.get(`${this.$domini}anysInstalacio?idInstalacio=135`, { withCredentials: true},{timeout: 2000000});
      this.anysDisponibles = response.data.anys; 
      console.log(this.anysDisponibles);
    } catch (error) {
      console.error('Error al calcular el cost', error);
    }
  },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    amagaInautoritzat() {
      this.$refs['modal-inautoritzat'].hide();
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },async iniciaAxios() {
      var d = new Date();
      this.formattedDate = `${d.getDate()}/${d.getMonth()+1 }/${d.getFullYear()}`;
      this.formattedDay = d.getDate();
      this.formattedMonth = d.getMonth()+1 ;
      
    
      this.formattedYear = d.getUTCFullYear();
      this.ComunitatsAxios();
      return this.BuscarAxios();
    }, decrementDate() {
      
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() - 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },
    incrementDate() {
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() + 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },
    formatPicker: function () {
      var d = new Date(this.model.date);
      this.formattedDate = `${d.getDate()}/${d.getMonth()+1 }/${d.getFullYear()}`;
      this.formattedDay = d.getDate();
      this.formattedMonth = d.getMonth()+1;
      this.formattedYear = d.getFullYear();
      
      this.ComunitatsAxios();
      
      return this.BuscarAxios();
    },
    async ComunitatsAxios() {
      this.data = new Date;
      this.any = this.data.getYear() - 100;
      console.log("ANY_Comun", this.any);

      this.carregant = true;
      await axios.get(this.$domini + 'llistaComunitats?codiCE=' + this.codiCE, { withCredentials: true})
        .then((response) => {


          console.log("responseABC", response);
          this.DetallsApi = response.data;
        })
        .catch((err) => {
          console.log("responseerr", err);
          this.carregant = 0;
          alert("\nError:\n\nNo s'han trobat dades.\nSi us plau, torni-ho a intentar o seleccioni una altra data.");
          console.error(err);
        });

      if (this.DetallsApi.exit == "unauthorized") {
        this.$refs['modal-inautoritzat'].show();
      }

      this.DadesInst = this.DetallsApi;
      this.carregant = false;
      this.carregaMapa = true;
      
      console.log(this.DadesInst.length);
      for (let i = 0; i < this.DadesInst.length - 1; i++) {
        console.log("thishererighthere",this.DadesInst[i].id);
        if (this.DadesInst[i].sistema == 2) {
          if (this.DadesInst[i].critiques !== 0 || this.DadesInst[i].majors !== 0) {
            this.DadesInst[i].estat = 3;
          } else if (this.DadesInst[i].menors !== 0 || this.DadesInst[i].avisos !== 0) {
            this.DadesInst[i].estat = 2;
          } else {
            this.DadesInst[i].estat = 1;
          }
        }

        if (this.DadesInst[i].capacitat == 0) {
          this.DadesInst[i].capacitat = "Sense bateria";
        }
      }

      await axios.get(this.$domini + 'energiaanual_Comunitat?idComunitat=' + this.codiCE + "&any=" + this.formattedYear + "&mes=" + this.formattedMonth + "&MensualQ=" + this.tipusR, { withCredentials: true})
        .then((response) => {
          this.dataApi = response.data;
        });
        
      this.CSOLAR = (this.dataApi.CoberturaSolar * 100).toFixed();
      this.percAutoconsum = (this.dataApi.percAutoconsum * 100).toFixed();
      this.QuotaAutarquica = (this.dataApi.QuotaAutarquica * 100).toFixed();
      this.dataAutoconsum = {
        labels: ["Autoconsum", "A xarxa"],
        datasets: [{
          backgroundColor: ["#0c4105c7", "#B6FF9F"],
          hoverBackgroundColor: ["#008f39", "#d1e3f7"],
          data: [this.percAutoconsum, 100 - this.percAutoconsum],
        }]
      };

      this.dataAutarquica = {
        labels: ["Autoconsum", "Consumida xarxa"],
        datasets: [{
          backgroundColor: ["#0c4105c7", "#B6FF9F"],
          hoverBackgroundColor: ["#008f39", "#d1e3f7"],
          data: [this.QuotaAutarquica, 100 - this.QuotaAutarquica],
        }]
      };

      this.dadesCarregades = true; // Nou
    },
  },
};
</script>

<style scoped>

.overflow-row {
  overflow-x: scroll;
  white-space: nowrap;  
 
  border-radius: 5px;
}

.btn-success5 {
  background: #B6FF9F;
  border: none;
  color: rgb(66, 65, 71);
  border-radius: 10px;
  width:100%;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding:0;
  margin:0;
}

.encuadrat7 {
  text-align: center;
  width: 100%;
  padding: 25px;
  margin: 5px;
  border: 3px solid rgb(201, 201, 201);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 374.98px) {
  .btn-success5 {
    font-size: 8px;
  }
}

@media (min-width: 375px) and (max-width: 789.98px) {
  .btn-success5 {
    font-size: 11px;
  }
}

/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 790px) and (max-width: 995.98px) {
  .btn-success5 {
    font-size: 11px;
  }
  .btn-success3 {
    width: 50%;
    
  }
}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 996px) and (max-width: 1199.98px) {
  .btn-success5 {
    font-size: 12px;
  }
  .btn-success3 {
    width: 50%;
  }
}

/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
  .btn-success5 {
    font-size: 15px;
  }
  .btn-success3 {
    width: 50%;
  }
}
h2 img {
  filter: invert(18%) sepia(34%) saturate(7485%) hue-rotate(101deg) brightness(70%) contrast(50%);
}

table {
  font-family: 'Open Sans', sans-serif;
  background-color: #d9ffd9;
  border-radius: 20px;
  border-collapse: collapse; 
  width: 100%;
  margin-bottom: 10px;
}

.common-table {
  width: 100%; 
}

table tr {
  background: #0c4105c7;
  color: #d9ffd9;
  padding: 4px;
  height: 25px;
}

table th {
  padding: 2px;
  word-wrap: break-word; 
  text-align: center;
}

table a {
  color: inherit; 
}

.triadates {
  border: none !important;
  border-bottom: 1px solid #0c4105c7 !important;
  height: 40px;
  text-align: center;
  position: relative;
}

table th:last-child {
  border-right: none;
}

table tbody tr:last-child th:last-child {
  /* Només arrodoneix la darrera columna de l'última fila */
  border-bottom-right-radius: 20px;
}

table tbody tr:nth-child(2n) th {
  background: #d9ffd9;
  color: #0c4105c7;
}

table th, table th {
  box-shadow: 0 4px 8px rgb(253, 253, 253);
}

h3{
    color: #0c4105c7;
    text-align: left;
    font-weight: bold;
    font-size: 20px;
    align-content: center;
    align-items: center;
}
b{
    color: #0c4105c7;
    text-align: center;
    font-weight: bold;
    font-size: x-large;
  
}

h2{
  color: #0c4105c7;
  font-size:small;
  margin-top: -2rem;
  text-align: center;
}

h4{
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  text-align: center;
  border-collapse: separate;
  table-layout: fixed;
  font-weight: bold;
}


#fletxaBaixa {
        
        width: auto;
        margin:0px;
        height: 15px;
        filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(186deg) brightness(104%) contrast(103%);
       
    }

#fletxaAlta {

        width: auto;
        margin:0px;
        height: 15px;
        filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(186deg) brightness(104%) contrast(103%);
    }
.btn-secondary{
    font-size: 12px!important;
  
}

h5{
  font-size: 15px;
  margin:4px;
}

.bunyol {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

table td, table th {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}


.bunyol2 {
  text-align: center;
  justify-content: center;
  align-items: center;

}

.mapa {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.carregar {
  text-align: center;
 
}
.InfoTaula {
  font-size: 15px;

}

#caracteristiques b-col {
  border: none;
  border-radius: 10px;
}

#caracteristiques b-row:nth-child(even){
  background-color: #B6FF9F;
  font-weight: bold;

}
@media (max-width: 374.98px) {
  h4 {
    font-size: 12px;
  }

  h3 {
    font-size: 15px;
  }
  .title {
    font-size: 12px;
  }
  .InfoTaula {
    font-size: 12px;
  }
  .btn {
    height: 30px;
  }

  #fletxaAlta {
    height:8px;
  }
  #fletxaBaixa {
    height:8px;
  }
  table tr {
    height: 10px;
  }

}

@media (min-width: 375px) and (max-width: 575.98px) {
    h4 {
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }
  .InfoTaula {
    font-size: 12px;
  }
  table tr {
    height: 13px;
  }
  #fletxaAlta {
    height: 8px;
  }
  #fletxaBaixa {
    height: 8px;
  }
}

@media (min-width: 576px) and (max-width: 789.98px) {
  h4 {
    font-size: 12px;
  }
  .title {
    font-size: 12px;
  }
  .InfoTaula {
    font-size: 12px;
  }
  table tr {
    height: 15px;
  }
 
  #fletxaAlta {
    height: 9px;
  }
  #fletxaBaixa {
    height: 9px;
  }
}

/* For medium devices (tablets, 768px to 991.98px wide) */
@media (min-width: 790px) and (max-width: 995.98px) {
  h4 {
    font-size: 13px;
  }
  .title {
    font-size: 13px;
  }
  .InfoTaula {
    font-size: 13px;
  }
  table tr {
    height: 21px;
  }
  #fletxaAlta {
    height: 11px;
  }
  #fletxaBaixa {
    height: 11px;
  }
}

/* For large devices (desktops, 992px to 1199.98px wide) */
@media (min-width: 996px) and (max-width: 1199.98px) {
  h4 {
    font-size: 14px;
  }
  .title {
    font-size: 14px;
  }

  .InfoTaula {
    font-size: 14px;
  }
  table tr {
    height: 24px;
  }
  
  #fletxaAlta {
    height: 13px;
  }
  #fletxaBaixa {
    height: 13px;
  }
}

/* For extra-large devices (large desktops, 1200px and above) */
@media (min-width: 1200px) {
  h4 {
    font-size: 15px;
  }
  .title {
    font-size: 15px;
  }
  .InfoTaula {
    font-size: 15px;
  }
  table tr {
    height: 25px;
  }

  .btn {
    height: 35px;   
  }
  #fletxaAlta {
    height: 9px;
  }
  #fletxaBaixa {
    height: 9px;
  }
}

</style>
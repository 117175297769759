<template>
  <div id="esquema" class="encuadrat2">
    <!-- Modal -->
    <b-modal ref="my-modal" header-bg-variant="success" header-text-variant="light" hide-footer centered title="Missatge" hide-header-close>
      <div class="d-block text-center">
        <h4>No s'han trobat les dades sol·licitades</h4>
      </div>
      <b-button class="mt-3" variant="outline-success" block @click="hideModal">Tanca</b-button>
    </b-modal>

    <b-container id="taulaBarLine7" fluid>
      <div id="ElemSuperior">
        <b-row>
          <b-col class="d-flex jusitify-items-center align-items-center">
            <b-button variant="link" @click="decrementDate">
              <b-icon icon="chevron-left"></b-icon>
            </b-button>
            <datepicker id="datepickerD" input-class="triadates" :placeholder="dateString" v-model="model.date" @input="formatPicker" :language="ca" :format="DatePickerFormat"></datepicker>
            <b-button variant="link" @click="incrementDate">
              <b-icon icon="chevron-right"></b-icon>
            </b-button> 
          </b-col>
          <b-col id="but">
            <button type="button" class="btn-success3" @click="functionAnual()" :class="{ 'active-button': tipus === 1 }">Anual</button>
          </b-col>
          <b-col id="buta">
            <button type="button" class="btn-success3" @click="functionMensual()" :class="{ 'active-button': tipus === 2 }">Mensual</button>
          </b-col>
          <b-col id="butb">
            <button type="button" class="btn-success3" @click="functionDiaria()" :class="{ 'active-button': tipus === 3 }">Diària</button>
          </b-col>
        </b-row>
        <div v-if="!chartData"><p class="custom-text-style">{{ text }}</p></div>
      </div>
     
      
      <b-row>
        <div id="graficalineas2" class="chart-container">
          <div class="chart-wrapper">
            <ChartStackedBar :chart-data="chartData" />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios';
import datepicker from 'vuejs-datepicker';
import ChartStackedBar from "./ChartStackedBar.js";
import { ca } from 'vuejs-datepicker/dist/locale';

export default {
  components: {
    datepicker,
    ChartStackedBar,
  },
  props: {
    idInstalacio: {},
    idComunitat: {},
    asistema: {}
  },
  data() {
    return {
      codiCE: this.idComunitat,
      ca: ca,
      carregant: 0,
      chartData: null,
      formattedDate: '',
      unitat: '',
      formattedDay: '',
      formattedMonth: '',
      formattedYear: '',
      formattedHour: '',
      infoapi1: null,
      infoapi2: null,
      infoapi4: null,
      ListaValores: [],
      ListaHora: [],
      tipus: 1,
      text: "",
      model: {
        date: new Date()
      },
      date: {
        from: null,
        to: null,
        month: null,
        year: null
      },
      dateString: "",
      eGenerada: "",
      DatePickerFormat: "dd/MM/yyyy",
      activeButton: '',
    };
  },
  mounted() {
    this.iniciaAxios();
  },
  methods: {
    showDate(date) {
      this.date = date
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },

    iniciaAxios() {
      var d = new Date();
      this.formattedDate = d.getUTCDate() + "/" + (d.getUTCMonth() + 1) + "/" + d.getUTCFullYear();
      this.formattedDay = d.getUTCDate();
      this.formattedMonth = d.getUTCMonth() + 1;
      this.formattedYear = d.getUTCFullYear();
      console.log("ANY_eGen2", this.formattedYear);
      //this.formattedYear = 2023;
      this.dateString = `${this.formattedDay.toString().padStart(2, '0')}/${this.formattedMonth.toString().padStart(2, '0')}/${this.formattedYear.toString()}`;
      this.tipus = 1;
      if (this.asistema === 3 || this.asistema === 4) {
        this.unitat = "Wh";
      } else if (this.asistema === 5) {
        this.unitat = "kWh";
      }else if (this.asistema === 7) {
        this.unitat = "kWh";
      } else if (this.asistema === 9) {
        this.unitat = "kWh";
      }
      else if (this.asistema === 10) {
        this.unitat = "kWh";
      }
      return this.PonerAxios();
    },
    decrementDate() {
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() - 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },
    incrementDate() {
      let newDate = new Date(this.model.date);
      newDate.setDate(newDate.getDate() + 1);
      this.model.date = new Date(newDate); // Ensure new Date object
      this.formatPicker();
    },

    formatPicker() {
      var d = new Date(this.model.date);
      this.formattedDate = `${d.getUTCDate()}/${d.getUTCMonth() + 1}/${d.getUTCFullYear()}`;
      this.formattedDay = d.getUTCDate();
      this.formattedMonth = d.getUTCMonth() + 1;
      this.formattedYear = d.getUTCFullYear();
      console.log("ANY_EGEN", this.formattedYear);
      //this.formattedYear = 2023;
      return this.PonerAxios();
    },

    functionAnual() {
      this.activeButton = 'anual';
      this.tipus = 1;
      return this.PonerAxios();
    },

    functionMensual() {
      this.activeButton = 'mensual';
      this.tipus = 2;
      return this.PonerAxios();
    },

    functionDiaria() {
      this.activeButton = 'diaria';
      this.tipus = 3;
      return this.PonerAxios();
    },

    PonerAxios() {
      this.ListaValores = [];
      this.eGenerada = [];
      this.ListaHora = [];
      this.infoapi = null;
      this.text = null;
      return this.BuscarAxios();
    },

    async BuscarAxios() {
      this.carregant = true;
      console.log("COMUNITAT: ", this.idComunitat);
      console.log("Instalacio: ", this.idInstalacio);

      try {
        const response = await axios.get(`${this.$domini}consumsGenerador?codiCE=${this.idComunitat}&Generador=${this.idInstalacio}`, { withCredentials: true, timeout: 2000000 });
        console.log("resposta1", response);
        this.infoapi1 = response.data;
        console.log("ASSIGconsums", this.infoapi1);
        let primer = true;

        for (let i = 0; i < this.infoapi1.length; i++) {
          let consum = this.infoapi1[i];
          console.log("ASSIGconsum", consum);
          let idConsum = consum.id;
          console.log("ASSIGidConsum", idConsum);

          const response3 = await axios.get(`${this.$domini}energiaConsumicio?idInstalacio=${idConsum}&tipus=${this.tipus}&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true, timeout: 2000000 });

          if (primer) {
            this.infoapi2 = response3.data;
            if (this.infoapi2.eConsumida != 0) {
              console.log("consINFOapi: ", this.infoapi2);
              console.log("consINFOapi2: ", this.infoapi2.eConsumida);
              primer = false;
            }

          }
          else {
            this.infoapi4 = response3.data;

            if (this.infoapi2.eConsumida && this.infoapi4.eConsumida) {

              if (this.infoapi2.eConsumida.length === this.infoapi4.eConsumida.length) {

                this.infoapi2.eConsumida = this.infoapi2.eConsumida.map((valor, index) => valor + this.infoapi4.eConsumida[index]);
                console.log("consINFOapi2Primer: ", this.infoapi2.eConsumida);
              } else {
                console.error(" longituds de infoapi2 y infoapi4 no son iguals");

              }
            }
          }

        }

        const response1 = await axios.get(`${this.$domini}energiaCE?idInstalacio=${this.idInstalacio}&tipus=${this.tipus}&dia=${this.formattedDay}&mes=${this.formattedMonth}&any=${this.formattedYear}`, { withCredentials: true, timeout: 2000000 });
        this.infoapi = response1.data;

        console.log("ASSIGgen2", this.infoapi.eGenerada);
      } catch (err) {
        this.handleError(err);
      }

      this.carregant = 0;
      await this.PonerGrafica();
    },
    PonerGrafica() {
      this.chartData = {
        labels: this.infoapi.temps,
        datasets: [],
      };

      if (this.infoapi && this.infoapi.eGenerada) {
        this.chartData.datasets.push({
          backgroundColor: "#0c4105c7",
          borderColor: `rgba(255, ${170 - 30}, ${80 - 40}, 1)`,
          borderWidth: 0.5,
          label: "Energia Entregada (" + this.unitat + ")",
          data: this.infoapi.eGenerada,
          stack: 'Stack 1',
        });
      }

      if (this.infoapi2 && this.infoapi2.eConsumida) {
        this.chartData.datasets.push({
          backgroundColor: `rgba(255, ${170 - 30}, ${80 - 40}, 1)`,
          borderColor: "#0c4105c7",
          borderWidth: 0.5,
          label: "Consum (" + this.unitat + ")",
          data: this.infoapi2.eConsumida,
          stack: 'Stack 0',
        });
      }
    },

    handleError(err) {
      console.error("Error:", err);
      // Gestió d'errors
    },
  },
};
</script>

<style>
#but button {
  margin: 5px;
}
#buta button {
  margin: 5px;
}
#butb button {
  margin: 5px;
}
#caracteristiques2 {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 15px;
  text-align: center;
  border-collapse: separate;
  table-layout: fixed;
}

#caracteristiques2 td, #caracteristiques2 th {
  border: none;
  border-radius: 10px;
}
.triadates {
  border: none !important;
  border-bottom: 1px solid #0c4105c7 !important;
  height: 40px;
  text-align: center;
}

.btn-success {
  background: #0c4105c7;
  color: #d9ffd9;
  border-radius: 5px;
  font-size: 16px;
  height: 35px;
  width: 100%;
}

.btn-success3 {
  background: #0c4105c7;
  color: #d9ffd9;
  border-radius: 5px;
  padding: 0%;
  margin: 0%;
  height: 35px;
  width: 100%;
}

.active-button {
  background-color: #B6FF9F;
  border-color: #B6FF9F;
  color:black;
}

.custom-text-style {
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.triadates {
  width: 100px;
}
.chart-container {
  position: relative;
  margin: auto;
 
}
.chart-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}


.graficalineas2 {
  width: 100%;
}

@media (max-width: 379.98px) {
  .btn-success {
    font-size: 12px;
    width: 90%;
  }
  .triadates {
    font-size: 12px;
    width: 50px;
    height: 8px;
  }
  .custom-margin {
    width: 50px;
    height: 35px;
  }
}

@media (min-width: 380px) and (max-width: 575.98px) {
  .btn-success {
    font-size: 10px;
    width: 70%;
  }
  .triadates {
    font-size: 10px;
    width: 55px;
    height: 10px;
  }
  .ElemSuperior {
    margin: 0px;
    padding: 0px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .btn-success {
    font-size: 10px;
    width: 70%;
  }
  .triadates {
    font-size: 10px;
    width: 55px;
    height: 10px;
  }
  .ElemSuperior {
    margin: 0px;
    padding: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .btn-success {
    font-size: 12px;
    width: 60%;
  }
  .triadates {
    font-size: 12px;
    width: 70px;
    height: 12px;
  }
  .custom-margin {
    margin: 0px;
    padding: 0px;
  }
}

@media (min-width: 1200px) {
  .btn-success {
    font-size: 14px;
    width: 50%;
  }
  .triadates {
    font-size: 14px;
    width: 80px;
    height: 14px;
  }
  .custom-margin {
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 337.98px) {
  .triadates {
    font-size: 12px;
    width: 40px;
    height: 8px;
  }
  .btn-success3 {
    font-size: 12px;
    width: 40px;
  }
}

@media (min-width: 338px) and (max-width: 379.98px) {
  .btn-success3 {
    font-size: 12px;
    width: 50px;
  }
}

@media (min-width: 380px) and (max-width: 575.98px) {
  .btn-success3 {
    font-size: 10px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .btn-success3 {
    font-size: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .btn-success3 {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .btn-success3 {
    font-size: 14px;
  }
}

</style>
